@import "custom";

body {
    font-family: 'Roboto', sans-serif;
    position: relative;
    min-height: 100vh;

    /* &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/bg.png);
        opacity: 0.3;
        content: "";
        pointer-events: none;
        z-index: -1;
        background-size: cover;
    } */
}

#header-promo {
    position: relative;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/bg.png);
        opacity: 0.3;
        content: "";
        pointer-events: none;
        z-index: -1;
        background-size: cover;
    }
}

body.sticky {
    #header-promo{
        padding-top: 89px;
    }
    #headerContainer {
        transition: 0.3s;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        background-color: $light;
        box-shadow: 0 .5rem 1rem #00000026;
    }
}

ul.navbar-nav{
    margin-right: 0 !important;
}

.box-logo {
    img {
        max-width: 50px;
    }
}

.first-section {
    img {
        max-width: 240px;
    }
}

#section-services {
    svg {
        width: auto;
        height: 5rem;
        color: $primary;
    }
}

#about-app {
    img {
        max-width: 240px;
    }
}

.section-padding {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

#footer {
    .box-logo {
        img {
            max-width: 160px;
        }
    }
}

.accordion-button {
    &:focus {
        box-shadow: none;
    }
}

.accordion {
    .accordion-item {
        border-radius: 0;
    }

    .accordion-button:not(.collapsed) {
        background-color: $primary;
        color: $light;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
}

.screen-image {
    max-width: 250px;
}

.screen-frame {
    position: relative;
    max-width: 320px;

    img {
        position: relative;
    }

    &:before {
        background-image: url("../images/file.gif");
        content: "";
        height: 86%;
        width: 90%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 3%;
        left: 2%;
    }
}

iframe.youtube {
    width: 100%;
    height: 50vw;
    max-width: 1000px;
    max-height: 600px;
    margin: auto;
    display: block;
}

.section-image {
    max-height: 270px;
}

#examples {
    position: relative;
    background-color: $light;
    z-index: 0;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/bg.png);
        opacity: 0.1;
        content: "";
        pointer-events: none;
        z-index: -1;
        background-size: cover;
    }
}

#sidebar {
    width: 300px;
    max-width: 300px;
}

#phone{
    h1{
        font-size: 3.5rem;
    }
}

@include media-breakpoint-down(lg) {
    #examples{
        .container{
            .row:nth-child(2n) {
                flex-direction: column-reverse;    
            }
        }
    }
    .section-padding {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    #phone h1{
        font-size: 2rem;
    }
    .first-section img{
        max-width: 200px;
    }

    nav.navbar{
        border-width: 0px !important;
        padding: 1rem 0 !important;
    }
}